import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { Redirect } from 'react-router';
import Header from './components/header';
import Login from './components/login2';
import Signup from './components/signup3';
import Main from './components/main';


const checkAuth = () => {
  let isAuth = localStorage.getItem("access_token")? true: false;
  // console.log("is authentic : ", isAuth)
  return isAuth;
};

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route exact path="/login?" element={<Login/>} />
        <Route path="/signup" element={<Signup/>} />
        <Route path='/main' element={checkAuth()?(<Main/>):<Navigate to='/login?' replace/>} />
      </Routes>
    </Router>
  );
}

export default App;
