import React from 'react';
import '../App.css';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import axios from 'axios';

// const handleLogout = () => {
//   setIsLoggedIn(false);
// };

function Header({isLoggedIn}){

  axios.defaults.withCredentials = true

  const handleLogout = async () => {
    // const logout = await fetch("https://thevideodictionary.com/user_logout", {
    //   "mode": 'cors',
    //   "method": 'POST',
    //   "credentials": 'include',
    //   "headers": {
    //     "Authorization": "Bearer " + localStorage.getItem("access_token")
    //   }
    // })

    const logout = await axios.post("https://thevideodictionary.com/user_logout", {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        withCredentials: true,
    });

    console.log("logout : ", logout)
    if(logout.status === 200){
      localStorage.clear("access_token")
      window.location.href = "/login"
    }else{
      console.log("Couldn't logout! Err: ", logout)
    }
  }

  // const handleLogout = async () => {
  //   // let config = {
  //   //   method: 'get',
  //   //   maxBodyLength: Infinity,
  //   //   url: 'http://127.0.0.1:8000/logout',
  //   //   headers: { 
  //   //     'Cookie': 'session='+localStorage.getItem("session")
  //   //   }
  //   // };
  //   const logout = await axios.get("http://127.0.0.1:8000/logout", 
  //   // {headers: {"Cookie": "session="+localStorage.getItem("session")}}, 
  //   {withCredentials: true});
  //   // const logout = await axios(config);

  //   if(logout.status === 200){
  //     console.log("Logged out")
  //   } else{
  //     console.log("problem logging out")
  //   }

  // };

  return (
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="#home">Project Shikshan</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                {/* <Nav.Link href="#courses">Courses</Nav.Link> */}
                <NavDropdown title="My Account" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#profile">My Profile</NavDropdown.Item>
                  <NavDropdown.Item href="#settings">Settings</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* isLoggedIn?(<Form className="d-flex">
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
              </Form>):() */}
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
  )
}

export default Header;
