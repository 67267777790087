import React, { useState, useEffect } from 'react';

const ErrorPopup = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`error-popup ${visible ? 'visible' : ''}`}>
      {message}
    </div>
  );
};

export default ErrorPopup;
