import React, { useState, useEffect } from 'react';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import axios from 'axios';


export default function Login(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [ip, setIp] = useState();

    const getIP = async () => {
      const response = await axios.get('https://api.ipify.org?format=json', {withCredentials: false})
      const data = response
      setIp(data.data.ip)
    }

    useEffect(() => {
      getIP()
    }, [])

    const handleSignup = async (e) => {
        e.preventDefault();
        // TODO: Add authentication logic here
        // setIsLoggedIn(true);
        // try{
        //     axios.post("http://127.0.0.1:8000/signup", {
        //         "email": email,
        //         "password": password,
        //         "confirm_password": confirm_password
        //     }, {headers: {
        //         "X-CSRFToken": "your-secret-key"
        //     }})
        //     .then((res) => {
        //       console.log(res);
        //     });
        //   }catch(error){
        //     console.log(error);
        //   }
        if (password !== confirm_password) {
            alert('Passwords do not match');
            return;
          }
        //   fetch('https://thevideodictionary.com/new_signup', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     //   "X-CSRFToken": "your-secret-key",
        //     },
        //     body: JSON.stringify({
        //         "email": email,
        //         "password": password,
        //         "confirm_password": confirm_password,
        //         "ip": ip
        //     })
        //   })
        //   .then(res => {
        //     if (res.ok) {
        //       window.location.href = '/login';
        //     } else {
        //       alert('Failed to create account');
        //     }
        //   });

        const requestData = JSON.stringify({
            email,
            password,
            confirm_password,
            ip,
        });

        try{
            const response = await axios.post('https://thevideodictionary.com/new_signup', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-CSRFToken': 'your-secret-key', // You can include CSRF token if needed
                },
            });

            if (response.status === 201) {
                window.location.href = '/login';
            } else {
                alert('Failed to create account');
            }
        } catch (error) {
            console.error('Error creating account:', error);
        }
    };

    return (
        <div>
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                {/* <Col className="vh-100 d-flex justify-content-left align-items-left"> */}
                <Col xs={{ span: 5, offset: 0 }}>
                <div>
                        <p>
                        About <br/>
                        ===== <br/>
                        <br/>
    The human brain processes videos/ images 60,000 times faster than text, and 90 percent of information transmitted to the brain is visual. Since we are visual by nature, we can use this skill to enhance data processing and memorization - Harris Eisenberg, 
    <br/>
    <br/>
    <br/>

    Landmark studies in the 1970s demonstrated that after viewing 10,000 scenes for a few seconds each, people could determine which of two had been seen with 83 percent accuracy. We want to apply the same concepts to simplify education, particularly in the field of language education. Learn 5000 words in less than 10 hrs through reels/ shorts video format from your most popular characters, shows and movies.

                        </p>
                    </div>
                    {/* </Col> */}
                </Col>
                {/* <Col className="vh-100 d-flex justify-content-right align-items-right"> */}
                <Col xs={{ span: 4, offset: 1 }}>
                    
                    <Card className="shadow">
                        <Card.Body>
                        <div className="mb-3 mt-md-4">
                            <h2 className="fw-bold mb-2">Sign-up</h2>
                            <div className="mb-3">
                            <Form onSubmit={ handleSignup }>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" 
                                    value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" 
                                    value={password} onChange={(e) => setPassword(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" 
                                    value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                </Form.Group>

                                <div className="d-grid">
                                <Button variant="primary" type="submit">Sign up</Button>
                                </div>
                            </Form>
                            </div>
                        </div>
                        </Card.Body>
                    </Card>
                    {/* </Col> */}
                </Col>
                </Row>
            </Container>
        </div>
        );
}
