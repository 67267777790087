import React, { useEffect, useState } from 'react';
// import Cookies from 'js-cookie';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import ErrorPopup from './error.js';
import axios from 'axios';


function Main() {
  const [word, setword] = useState([]);
  const [videoData, setvideoData] = useState([]);
  const [wordSynonyms, setWordSynonyms] = useState([]);
  const [wordMeaning, setwordMeaning] = useState([]);
  const [wordType, setwordType] = useState([]);
  const [newsSample, setnewsSample] = useState([]);
  const [wordSample, setwordSample] = useState([]);
  const [userEmail, setuserEmail] = useState([]);
  const [error, setError] = useState('');
  

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevents the default right-click context menu
  };

  const handleDoubleClick = () => {
    // Add any logic you want to perform on double click
  };  

  const clearError = () => {
    setError('');
  };

  const getNewWord = async () => {
    try{
      const video_info = await axios.post("https://thevideodictionary.com/getWord2", 
      {"access_token": localStorage.getItem("access_token")}, {withCredentials: true});
      console.log("video data : ", video_info, localStorage.getItem("access_token"))

      if (video_info.status === 200){
        if(video_info.data.hasOwnProperty("status")){
          setError(video_info.data.status);
          setword('');
          setvideoData('');
          // console.log("word synonyms: ", video_info.data.word_synonyms)
          setWordSynonyms('');
          setwordMeaning('');
          setwordType('');
          setnewsSample('');
          setwordSample('');

        }
          
        else{
          setword(video_info.data.word);
          
          // console.log("word synonyms: ", video_info.data.word_synonyms)
          setWordSynonyms(video_info.data.word_synonyms.slice(0, 3).toString());
          setwordMeaning(video_info.data.word_meaning);
          setwordType(video_info.data.word_type);
          setnewsSample(video_info.data.news_sample);
          setwordSample(video_info.data.word_sample);
          const base64VideoData = video_info.data.video_data; // Assuming 'data' contains the JSON response
          // const binaryVideoData = atob(base64VideoData);
          // const videoBlob = new Blob([binaryVideoData], { type: 'video/mp4' });
          // const videoURL = URL.createObjectURL(videoBlob);
          setvideoData(base64VideoData);


        }
      } else{
        console.log("Couldn't do it")
      }
      // .then((res) => {
      //   // let syn_words = res.data.word_synonyms.slice(0, 5).toString()
      //   setword(res.data.word);
      //   setvideoPath(res.data.video_path);
      //   setWordSynonyms(res.data.word_synonyms.slice(0, 3).toString());
      //   setTranscript(res.data.transcript);
      //   setwordMeaning(res.data.word_meaning);
      //   setwordType(res.data.word_type);
      //   setnewsSample(res.data.news_sample);
      //   setwordSample(res.data.word_sample);
      //   setvideoLink(res.data.video_link);
      // });
    }catch(error){
      console.log(error);
    }
  }

  // useEffect(() => {
  //   getNewWord()
  // })

  return (
    <Container className="mt-4">
      <Row>
        <Col md={8}>
          {/* <h2 className='Roboto Mono'>Welcome to the Learning Management System</h2> */}
          <video
                  onContextMenu={handleContextMenu}
                  onDoubleClick={handleDoubleClick}
                  controls src={ `data:video/mp4;base64,${videoData}` } 
                  type = "video/mp4"
                  style={{ width: '100%', maxWidth: '100%', height: 'auto' }} 
                  onError={(e) => {
                    console.error('Video error:', e.target.error);
                    // Handle the error as needed (e.g., show an error message)
                  }}
          />
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title><h1>{ word }</h1></Card.Title>
              <Card.Text>
                Word Meaning: { wordMeaning }
              </Card.Text>
              <Card.Text>
              Word Synonyms: { wordSynonyms }
              </Card.Text>
              <Card.Text>
              Word Sample: { wordSample }
              </Card.Text>
              <Card.Text>
              News Sample: { newsSample }
              </Card.Text>
              {/* <Card.Text>
              Word Transcript: { transcript }
              </Card.Text> */}

              <Button variant="primary" onClick={ getNewWord }>
                    Get Word
              </Button>
                
            </Card.Body>
          </Card>
        </Col>
        <Col>
        <h4>{error && <ErrorPopup message={error} onClose={clearError} />}</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default Main;
