import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";


export default function Login(){
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [ip, setIp] = useState();

    const getIP = async () => {
      const response = await axios.get('https://api.ipify.org?format=json', {withCredentials: false})
      const data = await response
      setIp(data.data.ip)
    }

    useEffect(() => {
      getIP()
    }, [])

    const handleLogin = (e) => {
        e.preventDefault();
        // TODO: Add authentication logic here
        // setIsLoggedIn(true);
      
        try{
          axios.post("https://thevideodictionary.com/user_login", {
              "email": email,
              "password": password,
              "ip": ip
          }, { withCredentials: true })
          .then((res) => {
            console.log("login res data : ", res);
            localStorage.setItem("access_token", res.data.access_token)
            window.location.href = "/main";
          });
        } catch(error){
          console.log(error);
        }
      }
 

    return (
        <div>
        <Container>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              {/* <div className="border border-3 border-primary"></div> */}
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-2">Sign in</h2>
                    <p className=" mb-5"></p>
                    <div className="mb-3">
                      <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            Email address
                          </Form.Label>
                          <Form.Control type="email" placeholder="Enter email" 
                            value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
  
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" placeholder="Password" 
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <p className="small">
                            <a className="text-primary" href="#!">
                              Forgot password?
                            </a>
                          </p>
                        </Form.Group>
                        <div className="d-grid">
                          <Button variant="primary" type="submit">
                            Login
                          </Button>
                        </div>
                      </Form>
                      <div className="mt-3">
                        <p className="mb-0  text-center">
                          Don't have an account?{" "}
                          <a href="/signup" className="text-primary fw-bold">
                            Sign Up
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>)
  
}
